import { useMutation } from '@apollo/client'
import { gql } from '@apollo/client'
import { optionalChaining } from 'src/utils'
import {
  DISPATCH_SCREEN_DATA,
  GET_CURRENT_AGENCY,
  GET_SUBMISSION
} from './queries'
import { cloneDeep } from '@apollo/client/utilities'

import {
  DISPATCH_SUBMISSION_FRAGMENT,
  FILE_FRAGMENT,
  FORM_FIELD_FRAGMENT,
  FORM_FIELD_ITEM_FRAGMENT,
  FORM_RESPONSE_FRAGMENT,
  STATUS_TEMPLATE_FRAGMENT,
  SUPERVISOR_REVIEW_CONFIG_FRAGMENT
} from './fragments'
import uniqBy from 'lodash/uniqBy'

export const SUBMIT_ACTIVITY = gql`
  mutation CreateSubmission($data: CreateSubmissionInput!) {
    createSubmission(data: $data) {
      id
      type {
        id
        label
      }
      callForService {
        id
        callNumber
      }
      incidentTime
    }
  }
`

export const SUBMIT_BULK_INCIDENT = gql`
  mutation SubmitBulkIncidents(
    $data: [BulkIncidentInput]!
    $locationId: ID
    $resources: [Resource]
    $incidentDate: DateTime!
    $notes: String
    $stepId: ID
  ) {
    submitBulkIncident(
      data: $data
      resources: $resources
      locationId: $locationId
      incidentDate: $incidentDate
      notes: $notes
      stepId: $stepId
    ) {
      success
    }
  }
`

export const UPDATE_SUBMISSION = gql`
  mutation UpdateOneSubmission(
    $data: SubmissionUpdateInput!
    $where: SubmissionWhereUniqueInput!
    $triggerSubscription: Boolean
  ) {
    updateOneSubmission(
      data: $data
      where: $where
      triggerSubscription: $triggerSubscription
    ) {
      id
      count
      notes {
        id
        text
        writtenBy {
          id
          name
        }
      }
      incidentTime
      type {
        id
        label
        subLabelOne
        subLabelTwo
        subLabelThree
      }
      vehicleIncidents {
        id
        passengers
        vehicle {
          id
          value
          type
          licenseNumber
        }
      }
      location {
        id
        lat
        lng
        prettyAddress
        position {
          id
          name
        }
      }
      type {
        id
        label
      }
      incidentTime
      dispatches {
        id
        unit {
          id
          positionStatus
          dispatchStatus
        }
        personnel {
          id
          name
        }
      }

      relatedSubmissions {
        id
        count
      }
    }
  }
`

export const useUpdateSubmission = options => {
  const [updateOneSubmission, { data, error, loading }] = useMutation(
    UPDATE_SUBMISSION,
    {
      refetchQueries: ['GetSubmission'],
      ...options
    }
  )
  const submission = optionalChaining(() => data.updateOneSubmission)

  return {
    updateOneSubmission,
    error,
    loading,
    submission
  }
}

const UPDATE_MANY_SUBMISSIONS = gql`
  mutation UpdateManySubmissions(
    $data: SubmissionManyUpdateInput!
    $where: SubmissionManyWhereInput!
  ) {
    updateManySubmissions(data: $data, where: $where) {
      success
    }
  }
`

export const useUpdateManySubmissions = options => {
  const [
    updateManySubmissions,
    { data, error, loading }
  ] = useMutation(UPDATE_MANY_SUBMISSIONS, {
    refetchQueries: ['GetSubmission'],
    ...options
  })
  const submission = optionalChaining(
    () => data.updateManySubmissions
  )

  return {
    updateManySubmissions,
    error,
    loading,
    submission
  }
}

const CREATE_DISPATCH = gql`
  mutation CreateSubmissionWithDispatch(
    $data: CreateSubmissionInput
  ) {
    createSubmission(data: $data) {
      ...DispatchSubmissionFragment
    }
  }
  ${DISPATCH_SUBMISSION_FRAGMENT}
`

export const useCreateNewSubmissionAndDispatch = options => {
  const defaultOptions = {
    ...options,
    update: (store, { data: { createSubmission } }) => {
      // Read the data from our cache for this query.
      let dispatchScreenData = cloneDeep(
        store.readQuery({
          query: DISPATCH_SCREEN_DATA
        })
      )

      // Due to realtime nature, avoid duplicates in case pusher is faster
      const dispatchExists = dispatchScreenData.myAgenciesSubmissionsWithActiveDispatches?.find(
        submission => submission.id === createSubmission.id
      )

      dispatchScreenData.myAgenciesSubmissionsWithActiveDispatches = dispatchExists
        ? dispatchScreenData.myAgenciesSubmissionsWithActiveDispatches
        : [
            createSubmission,
            ...dispatchScreenData.myAgenciesSubmissionsWithActiveDispatches
          ]
      // Write our data back to the cache.
      store.writeQuery({
        query: DISPATCH_SCREEN_DATA,
        data: { ...dispatchScreenData }
      })
    }
  }

  const [
    createNewSubmissionAndDispatch,
    { data, error, loading }
  ] = useMutation(CREATE_DISPATCH, defaultOptions)
  const submission = optionalChaining(() => data.createSubmission)

  return {
    createNewSubmissionAndDispatch,
    error,
    loading,
    submission
  }
}

const UPDATE_DISPATCH = gql`
  mutation UpdateOneDispatch(
    $data: DispatchUpdateInput!
    $where: DispatchWhereUniqueInput!
  ) {
    updateOneDispatch(data: $data, where: $where) {
      id
      createdAt
      enRoute
      completedAt
      onScene
    }
  }
`

export const useUpdateDispatch = options => {
  const defaultOptions = {
    ...options
  }
  const [updateOneDispatch, { data, error, loading }] = useMutation(
    UPDATE_DISPATCH,
    defaultOptions
  )
  const dispatch = optionalChaining(() => data.updateOneDispatch)

  return {
    updateOneDispatch,
    error,
    loading,
    dispatch
  }
}

const SAVE_REPORT = gql`
  mutation CreateOneReport($data: ReportCreateInput!) {
    createOneReport(data: $data) {
      id
      createdAt
    }
  }
`

export const useCreateReport = options => {
  const defaultOptions = {
    ...options
  }
  const [createOneReport, { data, error, loading }] = useMutation(
    SAVE_REPORT,
    defaultOptions
  )
  const report = optionalChaining(() => data.createOneReport)

  return {
    createOneReport,
    error,
    loading,
    report
  }
}

const UPDATE_REPORT = gql`
  mutation UpdateOneReport(
    $where: ReportWhereUniqueInput!
    $data: ReportUpdateInput!
  ) {
    updateOneReport(where: $where, data: $data) {
      id
      createdAt
      options
      name
      frequency
      emails
      cron
      skipSendOnEmpty
      active
      archived
      configError
    }
  }
`

export const useUpdateReport = options => {
  const defaultOptions = {
    ...options
  }
  const [updateOneReport, { data, error, loading }] = useMutation(
    UPDATE_REPORT,
    defaultOptions
  )
  const report = optionalChaining(() => data.updateOneReport)

  return {
    updateOneReport,
    error,
    loading,
    report
  }
}

const SAVE_STATUS_VALUE_REPORT = gql`
  mutation CreateOneStatusValueReport(
    $data: StatusValueReportCreateInput!
  ) {
    createOneStatusValueReport(data: $data) {
      id
      createdAt
    }
  }
`

export const useCreateStatusValueReport = options => {
  const defaultOptions = {
    ...options
  }
  const [createOneReport, { data, error, loading }] = useMutation(
    SAVE_STATUS_VALUE_REPORT,
    defaultOptions
  )
  const report = optionalChaining(() => data.createOneReport)

  return {
    createOneReport,
    error,
    loading,
    report
  }
}

const UPDATE_STATUS_VALUE_REPORT = gql`
  mutation UpdateOneStatusValueReport(
    $where: StatusValueReportWhereUniqueInput!
    $data: StatusValueReportUpdateInput!
  ) {
    updateOneStatusValueReport(where: $where, data: $data) {
      id
      createdAt
      options
      name
      archived
    }
  }
`

export const useUpdateStatusValueReport = options => {
  const defaultOptions = {
    ...options
  }
  const [
    updateOneStatusValueReport,
    { data, error, loading }
  ] = useMutation(UPDATE_STATUS_VALUE_REPORT, defaultOptions)
  const report = optionalChaining(
    () => data.updateOneStatusValueReport
  )

  return {
    updateOneStatusValueReport,
    error,
    loading,
    report
  }
}

export const DELETE_POSITION_CATEGORY = gql`
  mutation DeletePositionCategory($id: String!) {
    deleteOnePositionCategory(where: { id: $id }) {
      id
    }
  }
`

export const ARCHIVE_DISPATCH = gql`
  mutation DeleteDispatch($id: ID!) {
    archiveOneDispatch(id: $id) {
      id
      archived
    }
  }
`

export const SET_IMPORTANT = gql`
  mutation SetImportant($id: ID!) {
    setSubmissionImportant(id: $id) {
      id
      important
      reviewForm {
        id
        name
      }
      reviewStatus
      reviewStatusColor
    }
  }
`

export const ARCHIVE_SUBMISSION = gql`
  mutation archiveOneSubmission(
    $id: ID!
    $withRelated: Boolean
    $restore: Boolean
  ) {
    archiveOneSubmission(
      id: $id
      withRelated: $withRelated
      restore: $restore
    ) {
      id
      archived
      relatedSubmissions {
        id
        archived
      }
    }
  }
`

export const ARCHIVE_MANY_SUBMISSION = gql`
  mutation archiveManySubmissions($ids: [ID]!, $restore: Boolean) {
    archiveManySubmissions(ids: $ids, restore: $restore) {
      success
      data
    }
  }
`

export const CREATE_VEHICLE_INCIDENT = gql`
  mutation CreateVehicleIncident($data: VehicleIncidentsInput!) {
    createVehicleIncident(data: $data) {
      id
      id
      passengers
      vehicle {
        value
        licenseNumber
        type
      }
    }
  }
`

export const useCreateVehicleIncident = submissionId => {
  const [
    createVehicleIncident,
    { data, error, loading }
  ] = useMutation(CREATE_VEHICLE_INCIDENT, {
    update: (store, { data: upsertData }) => {
      // Read the data from our cache for this query.
      const createVehicleIncident = upsertData?.createVehicleIncident
      if (!createVehicleIncident) {
        return
      }
      const data = cloneDeep(
        store.readQuery({
          query: GET_SUBMISSION,
          variables: {
            where: {
              id: submissionId
            }
          }
        })
      )

      // Add our vehicle from the mutation to the end.

      data.submission.vehicleIncidents = [
        ...(data.submission.vehicleIncidents || []),
        createVehicleIncident
      ]

      // Write our data back to the cache.
      store.writeQuery({
        query: GET_SUBMISSION,
        data: { ...data }
      })
    }
  })

  const vehicle = optionalChaining(() => data.createVehicleIncident)

  return {
    vehicle,
    error,
    loading,
    createVehicleIncident
  }
}

const UPDATE_SUBMISSION_DISPATCHES = gql`
  mutation UpdateSubmissionDispatches(
    $data: SubmissionUpdateInput!
    $where: SubmissionWhereUniqueInput!
  ) {
    updateOneSubmission(data: $data, where: $where) {
      id
      location {
        id
        lat
        lng
        position {
          id
          name
        }
      }
      dispatches {
        id
        completedAt
        enRoute
        unit {
          id
          positionStatus
          dispatchStatus
        }
      }
    }
  }
`

export const useAddDispatches = options => {
  const defaultOptions = {
    ...options
  }
  const [updateOneSubmission, { data, error, loading }] = useMutation(
    UPDATE_SUBMISSION_DISPATCHES,
    defaultOptions
  )
  const dispatches = optionalChaining(
    () => data.updateOneSubmission.dispatches
  )

  return {
    addDispatches: updateOneSubmission,
    error,
    loading,
    dispatches
  }
}

export const useAddLocation = options => {
  const defaultOptions = {
    ...options
  }
  const [updateOneSubmission, { data, error, loading }] = useMutation(
    UPDATE_SUBMISSION_DISPATCHES,
    defaultOptions
  )
  const dispatches = optionalChaining(
    () => data.updateOneSubmission.dispatches
  )

  return {
    addLocation: async ({ submissionId, location }) => {
      return updateOneSubmission({
        variables: {
          where: {
            id: submissionId
          },
          data: {
            location: {
              upsert: {
                create: {
                  ...(location?.lat && { lat: location?.lat }),
                  ...(location?.lng && { lng: location?.lng }),
                  position: {
                    connect: {
                      id: location?.id
                    }
                  }
                },
                update: {
                  ...(location?.lat && { lat: location?.lat }),
                  ...(location?.lng && { lng: location?.lng }),
                  position: {
                    connect: {
                      id: location?.id
                    }
                  }
                }
              }
            }
          }
        }
      })
    },
    error,
    loading,
    dispatches
  }
}

const CALCULATE_EPCR_ID = gql`
  mutation CalculateEPCRId {
    calculateEPCRId(
      submissionId: "ck54qdnkf00020wl7og3xksr2"
      medicalReportCount: 1
    ) {
      success
      calculatedId
    }
  }
`

export const useCalculateEPCRId = options => {
  const defaultOptions = {
    ...options
  }
  const [calculateEPCRId, { data, error, loading }] = useMutation(
    CALCULATE_EPCR_ID,
    defaultOptions
  )
  const vehicle = optionalChaining(() => data.calculateEPCRId)

  return {
    vehicle,
    error,
    loading,
    calculateEPCRId
  }
}

export const UPDATE_AGENCY = gql`
  mutation UpdateAgency(
    $where: AgencyWhereUniqueInput!
    $data: AgencyUpdateInput!
  ) {
    updateOneAgency(where: $where, data: $data) {
      id
      beachRiskOverride
      users {
        id
        name
        role
      }
      positions {
        id
        name
        dispatchable
      }
      settings {
        id
        dispatchLocationDefault
        dispatchHideOutOfService
        dispatchTrackEnRoute
      }
    }
  }
`

export const UPDATE_AGENCY_LOGO = gql`
  mutation UpdateAgencyLogo($filestackId: String!) {
    updateAgencyLogo(filestackId: $filestackId) {
      success
      settings {
        logo {
          id
          filestackId
          secureFileUrl
        }
      }
    }
  }
`

export const UPDATE_NODE_IMAGE = gql`
  mutation UpdateAgencyNodeImage(
    $filestackId: String!
    $nodeId: ID!
    $nodeType: AgencyNodeTypeEnum!
  ) {
    updateAgencyNodeImage(
      filestackId: $filestackId
      nodeId: $nodeId
      nodeType: $nodeType
    ) {
      success
      data
    }
  }
`

export const CREATE_BULLETIN = gql`
  mutation CreateBulletin(
    $subject: String!
    $liveUntil: DateTime!
    $message: String!
    $userIds: [ID]
    $tagIds: [String]
    $isPrivate: Boolean
    $urgency: BulletinUrgencyEnum
    $attachmentIds: [ID]
  ) {
    createBulletin(
      subject: $subject
      liveUntil: $liveUntil
      message: $message
      userIds: $userIds
      attachmentIds: $attachmentIds
      tagIds: $tagIds
      isPrivate: $isPrivate
      urgency: $urgency
    ) {
      id
      createdAt
      updatedAt
      liveUntil
      isPrivate
      isActive
      body
      title
      urgency
      readByCount
      hasRead
      whiteList {
        id
        name
      }
      whiteListedGroups {
        id
        name
        color
      }
      author {
        id
        name
      }
      attachments {
        ...FileFragment
      }
    }
  }
  ${FILE_FRAGMENT}
`

export const UPDATE_BULLETIN = gql`
  mutation UpdateOneBulletin(
    $data: BulletinUpdateInput!
    $where: BulletinWhereUniqueInput!
  ) {
    updateOneBulletin(data: $data, where: $where) {
      id
      createdAt
      liveUntil
      isPrivate
      isActive
      body
      title
      urgency
      readByCount
      hasRead
      whiteList {
        id
        name
      }
      whiteListedGroups {
        id
        name
        color
      }
      author {
        id
        name
      }
      attachments {
        ...FileFragment
      }
    }
  }
  ${FILE_FRAGMENT}
`

const MARK_BULLETIN_READ = gql`
  mutation MarkBulletinRead($where: BulletinWhereUniqueInput!) {
    markBulletinRead(where: $where) {
      id
      hasRead
    }
  }
`

export const useUpdateBulletin = options => {
  const defaultOptions = {
    refetchQueries: ['GetBulletins'],
    ...options
  }
  const [updateBulletin, { data, error, loading }] = useMutation(
    UPDATE_BULLETIN,
    defaultOptions
  )
  const bulletin = optionalChaining(() => data.updateBulletin)

  return {
    bulletin,
    error,
    loading,
    updateBulletin
  }
}

export const useMarkBulletinRead = options => {
  const defaultOptions = {
    refetchQueries: ['GetBulletins'],
    ...options
  }
  const [markBulletinRead, { data, error, loading }] = useMutation(
    MARK_BULLETIN_READ,
    defaultOptions
  )
  const bulletin = optionalChaining(() => data.markBulletinRead)

  return {
    bulletin,
    error,
    loading,
    markBulletinRead
  }
}

export const OPEN_CHAT = gql`
  mutation OpenChat($bulletinId: String!, $days: Int!) {
    openChat(bulletinId: $bulletinId, days: $days) {
      success
      bulletin {
        id
        liveUntil
        isActive
        isPrivate
      }
    }
  }
`

export const CLOSE_CHAT = gql`
  mutation CloseChat($bulletinId: String!) {
    closeChat(bulletinId: $bulletinId) {
      success
      bulletin {
        id
        liveUntil
        isActive
        isPrivate
      }
    }
  }
`

export const useCloseChat = options => {
  const defaultOptions = {
    refetchQueries: ['GetBulletins'],
    awaitRefetchQueries: true,
    ...options
  }
  const [closeChat, { data, error, loading }] = useMutation(
    CLOSE_CHAT,
    defaultOptions
  )
  const res = optionalChaining(() => data.closeChat)

  return {
    data: res,
    error,
    loading,
    closeChat
  }
}

export const useOpenChat = options => {
  const defaultOptions = {
    refetchQueries: ['GetBulletins'],
    awaitRefetchQueries: true,
    ...options
  }
  const [openChat, { data, error, loading }] = useMutation(
    OPEN_CHAT,
    defaultOptions
  )
  const res = optionalChaining(() => data.openChat)

  return {
    data: res,
    error,
    loading,
    openChat
  }
}

export const CREATE_FILE = gql`
  mutation CreateFile($data: FileCreateInput!) {
    createOneFile(data: $data) {
      id
      createdAt
      name
      filestackId
    }
  }
`

export const useCreateFile = options => {
  const defaultOptions = {
    refetchQueries: ['GetAgencyFilesPList'],
    ...options
  }
  const [createOneFile, { data, error, loading }] = useMutation(
    CREATE_FILE,
    defaultOptions
  )
  const res = optionalChaining(() => data.createOneFile)

  return {
    data: res,
    error,
    loading,
    createOneFile
  }
}

export const UPDATE_FILE = gql`
  mutation UpdateFile(
    $where: FileWhereUniqueInput!
    $data: FileUpdateInput!
  ) {
    updateOneFile(data: $data, where: $where) {
      id
      createdAt
      name
      filestackId
      pinned
      url
    }
  }
`

export const useUpdateFile = options => {
  const defaultOptions = {
    ...options
  }
  const [updateOneFile, { data, error, loading }] = useMutation(
    UPDATE_FILE,
    defaultOptions
  )
  const res = optionalChaining(() => data.updateOneFile)

  return {
    data: res,
    error,
    loading,
    updateOneFile
  }
}

export const CREATE_FILE_CATEGORY = gql`
  mutation CreateFileCategory($data: FileCategoryCreateInput!) {
    createOneFileCategory(data: $data) {
      id
      createdAt
      name
      color
    }
  }
`

export const useCreateFileCategory = (options = {}) => {
  const [
    createOneFileCategory,
    { data, error, loading }
  ] = useMutation(CREATE_FILE_CATEGORY, {
    ...options,
    // refetchQueries: () => ['GetAgencyFilesPList']

    update: (store, { data: { createOneFileCategory } }) => {
      // Read the data from our cache for this query.
      const data = cloneDeep(
        store.readQuery({
          query: GET_CURRENT_AGENCY
        })
      )

      data.myAgency.fileCategories = data.myAgency.fileCategories
        ? [createOneFileCategory, ...data.myAgency.fileCategories]
        : []

      // Write our data back to the cache.
      store.writeQuery({
        query: GET_CURRENT_AGENCY,
        data: { ...data }
      })
    }
  })
  const res = optionalChaining(() => data.createOneFileCategory)

  return {
    data: res,
    error,
    loading,
    createOneFileCategory
  }
}

export const UPDATE_FILE_CATEGORY = gql`
  mutation UpdateFileCategory(
    $where: FileCategoryWhereUniqueInput!
    $data: FileCategoryUpdateInput!
  ) {
    updateOneFileCategory(data: $data, where: $where) {
      id
      createdAt
      name
      color
    }
  }
`

export const useUpdateFileCategory = options => {
  const defaultOptions = {
    refetchQueries: ['GetAgencyFilesPList'],
    ...options
  }
  const [
    updateOneFileCategory,
    { data, error, loading }
  ] = useMutation(UPDATE_FILE_CATEGORY, defaultOptions)
  const res = optionalChaining(() => data.updateOneFileCategory)

  return {
    data: res,
    error,
    loading,
    updateOneFileCategory
  }
}

export const SEND_NOTIFICATION = gql`
  mutation SendNotifications(
    $userIds: [ID]
    $tagIds: [ID]
    $message: String!
    $channels: [NotificationChannelEnum!]!
    $url: String
  ) {
    sendNotifications(
      userIds: $userIds
      message: $message
      tagIds: $tagIds
      channels: $channels
      url: $url
    ) {
      success
    }
  }
`

export const useSendNotification = options => {
  const defaultOptions = {
    ...options
  }
  const [sendNotifications, { data, error, loading }] = useMutation(
    SEND_NOTIFICATION,
    defaultOptions
  )
  const res = optionalChaining(() => data.sendNotifications)

  return {
    data: res,
    error,
    loading,
    sendNotifications
  }
}

export const CREATE_FORM = gql`
  mutation CreateForm($data: FormCreateInput!) {
    createOneForm(data: $data) {
      id
      createdAt
      name
      autoSave
      autoWeather
      activityTypes {
        id
        label
      }
      fields {
        ...FormFieldFragment
        fieldItems {
          ...FormFieldItemFragment
        }
      }
    }
  }
  ${FORM_FIELD_FRAGMENT}
  ${FORM_FIELD_ITEM_FRAGMENT}
`

export const useCreateForm = options => {
  const defaultOptions = {
    refetchQueries: ['GetAgencyForms'],
    update: (store, { data: d }) => {
      if (!d?.createOneForm) return
      const { createOneForm } = d
      // Read the data from our cache for this query.
      const data = cloneDeep(
        store.readQuery({
          query: GET_CURRENT_AGENCY
        })
      )

      if (data?.myAgency) {
        data.myAgency.activities = data.myAgency.activities.map(p => {
          return createOneForm.activityTypes.findIndex(
            at => at.id === p.id
          )
            ? {
                ...p,
                suggestedForms: [...p.suggestedForms, createOneForm]
              }
            : p
        })

        // Write our data back to the cache.
        store.writeQuery({
          query: GET_CURRENT_AGENCY,
          data: { ...data }
        })
      }
    },
    ...options
  }
  const [createOneForm, { data, error, loading }] = useMutation(
    CREATE_FORM,
    defaultOptions
  )
  const res = data?.createOneForm

  return {
    data: res,
    error,
    loading,
    createOneForm
  }
}

export const UPDATE_FORM = gql`
  mutation UpdateForm(
    $where: FormWhereUniqueInput!
    $data: FormUpdateInput!
  ) {
    updateOneForm(data: $data, where: $where) {
      id
      createdAt
      name
      pinned
      pinnedToSubmitScreen
      archived
      autoSave
      autoWeather
      supervisorReviewConfig {
        ...SupervisorReviewConfigFragment
      }
      activityTypes {
        id
        label
      }
      fields {
        ...FormFieldFragment
      }
    }
  }
  ${FORM_FIELD_FRAGMENT}
  ${SUPERVISOR_REVIEW_CONFIG_FRAGMENT}
`

export const useUpdateForm = options => {
  const defaultOptions = {
    update: (store, { data: { updateOneForm } }, rest) => {
      // Read the data from our cache for this query.
      const data = cloneDeep(
        store.readQuery({
          query: GET_CURRENT_AGENCY
        })
      )

      if (data?.myAgency && updateOneForm) {
        data.myAgency.activities = data.myAgency.activities.map(p => {
          const index = updateOneForm.activityTypes.findIndex(
            at => at.id === p.id
          )

          return index >= 0
            ? {
                ...p,
                suggestedForms: uniqBy(
                  [...p.suggestedForms, updateOneForm],
                  sf => sf.id
                )
              }
            : {
                ...p,
                suggestedForms: [
                  ...p.suggestedForms.filter(
                    f => f.id !== updateOneForm.id
                  )
                ]
              }
        })
      }

      // Write our data back to the cache.
      store.writeQuery({
        query: GET_CURRENT_AGENCY,
        data: { ...data }
      })
    },
    ...options
  }
  const [updateOneForm, { data, error, loading }] = useMutation(
    UPDATE_FORM,
    defaultOptions
  )

  const res = optionalChaining(() => data.updateOneForm)

  return {
    data: res,
    error,
    loading,
    updateOneForm
  }
}

export const SUBMIT_FORM = gql`
  mutation SubmitForm($data: SubmitFormInput!) {
    submitForm(data: $data) {
      success
      formResponse {
        ...FormResponseFragment
      }
    }
  }
  ${FORM_RESPONSE_FRAGMENT}
`

export const UPDATE_FORM_RESPONSE = gql`
  mutation UpdateFormResponse(
    $data: FormResponseUpdateInput!
    $where: FormResponseWhereUniqueInput!
  ) {
    updateOneFormResponse(data: $data, where: $where) {
      ...FormResponseFragment
    }
  }
  ${FORM_RESPONSE_FRAGMENT}
`

export const DELETE_ONE_FILE_CATEGORY = gql`
  mutation DeleteFileCategory($id: String!) {
    deleteOneFileCategory(where: { id: $id }) {
      id
    }
  }
`

export const ARCHIVE_ONE_FILE = gql`
  mutation ArchiveFile($id: ID!) {
    archiveOneFile(id: $id) {
      id
      archived
    }
  }
`

export const ARCHIVE_ONE_FORM = gql`
  mutation ArchiveForm($id: ID!) {
    archiveOneForm(id: $id) {
      id
      archived
    }
  }
`

export const ARCHIVE_ONE_FORM_RESPONSE = gql`
  mutation ArchiveFormResponse($id: ID!, $cascadeStatuses: Boolean) {
    archiveOneFormResponse(
      id: $id
      cascadeStatuses: $cascadeStatuses
    ) {
      id
      archived
    }
  }
`

export const SEND_INVITE = gql`
  mutation SendInvite(
    $email: String!
    $agencyId: ID
    $name: String
    $role: Role!
    $userId: ID
  ) {
    sendInviteEmail(
      email: $email
      agencyId: $agencyId
      name: $name
      role: $role
      userId: $userId
    ) {
      success
    }
  }
`
export const CREATE_USER = gql`
  mutation CreateUser($agencyId: ID, $name: String!) {
    createUser(agencyId: $agencyId, name: $name) {
      id
      name
    }
  }
`

export const REQUEST_PASSWORD = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email) {
      success
    }
  }
`
export const GET_CUBE_JS_AUTHORIZATION = gql`
  mutation GetCubeJsAuthorization {
    getCubeJSJwt
  }
`

export const CREATE_STATUS_TEMPLATE = gql`
  mutation CreateStatusTemplate($data: CreateStatusTemplateInput!) {
    createStatusTemplate(data: $data) {
      ...StatusTemplateFragment
    }
  }
  ${STATUS_TEMPLATE_FRAGMENT}
`

export const UPDATE_STATUS_TEMPLATE = gql`
  mutation UpdateStatusTemplate(
    $id: ID!
    $data: UpdateStatusTemplateInput!
  ) {
    updateStatusTemplate(id: $id, data: $data) {
      ...StatusTemplateFragment
      updatedBy {
        id
        active
        valueFromFormQuestion {
          id
          allowedValues
          formField {
            id
            form {
              id
              name
            }
          }
        }
      }
    }
  }
  ${STATUS_TEMPLATE_FRAGMENT}
`

export const MATCH_FIELD_ITEM_TO_STATUS_ALLOWED = gql`
  mutation MatchFormFieldItemAllowedValuesToStatus(
    $fieldItemId: ID!
    $statusTemplateId: ID!
  ) {
    matchFormFieldItemAllowedValuesToStatus(
      fieldItemId: $fieldItemId
      statusTemplateId: $statusTemplateId
    ) {
      id
      allowedValues
    }
  }
`

export const CREATE_STATUS_VALUE = gql`
  mutation CreateStatusValue($data: CreateStatusValueInput!) {
    createStatusValue(data: $data) {
      id
      value
      createdAt
      completedAt
      updatedAt
      prettyValue
      color
      relationId
      alertLevel
      expirationColor
      expirationStatus
    }
  }
`

export const TOGGLE_STATUS_AUTOMATION_ON = gql`
  mutation ToggleStatusAutomation($id: ID!) {
    toggleStatusAutomation(id: $id) {
      id
      active
    }
  }
`

export const CREATE_DISPATCH_AUTOMATION = gql`
  mutation CreateDispatchAutomation(
    $data: CreateDispatchAutomationInput
  ) {
    createDispatchAutomation(data: $data) {
      id
      incidentCategory {
        id
        label
      }
      hour
      minute
      frequency
      dayOfWeek
      dayOfMonth
      monthOfYear
    }
  }
`

export const TOGGLE_DISPATCH_AUTOMATION_ACTIVE = gql`
  mutation toggleDispatchAutomationActive($id: ID!) {
    toggleDispatchAutomation(id: $id) {
      id
      active
      incidentCategory {
        id
        label
      }
      frequency
      hour
      minute
      dayOfWeek
      dayOfMonth
      monthOfYear
    }
  }
`

export const UPDATE_DISPATCH_AUTOMATION = gql`
  mutation UpdateDispatchAutomation(
    $data: UpdateDispatchAutomationInput!
    $where: DispatchAutomationWhereUniqueInput!
  ) {
    updateDispatchAutomation(data: $data, where: $where) {
      id
      incidentCategory {
        id
        label
      }
      frequency
      hour
      minute
      dayOfWeek
      dayOfMonth
      monthOfYear
    }
  }
`

export const DELETE_DISPATCH_AUTOMATION = gql`
  mutation DeleteDispatchAutomation($id: ID!) {
    archiveOneDispatchAutomation(id: $id) {
      id
      archived
    }
  }
`

export const ENABLE_MFA = gql`
  mutation EnableMFA(
    $phoneNumber: String!
    $stytchMemberId: String
    $organizationId: String
  ) {
    enableMFA(
      phoneNumber: $phoneNumber
      stytchMemberId: $stytchMemberId
      organizationId: $organizationId
    ) {
      success
      data
    }
  }
`
export const VERIFY_MFA = gql`
  mutation VerifyMFA($code: String!) {
    verifyMFA(code: $code) {
      success
      data
    }
  }
`
export const VERIFY_MFA_LOGIN = gql`
  mutation VerifyMFALogin(
    $code: String!
    $sessionJwt: String!
    $stytchMemberId: String!
  ) {
    verifyMFALogin(
      code: $code
      sessionJwt: $sessionJwt
      stytchMemberId: $stytchMemberId
    ) {
      success
      data
    }
  }
`
export const RESEND_MFA_LOGIN = gql`
  mutation ResendMFALogin($stytchMemberId: String!) {
    resendMFALogin(stytchMemberId: $stytchMemberId) {
      success
      data
    }
  }
`

export const COMPLETE_ALL_DISPATCH = gql`
  mutation CompleteAllDispatch($submissionId: ID, $time: DateTime) {
    completeAllDispatchesBySubmission(
      id: $submissionId
      time: $time
    ) {
      id
      dispatches {
        id
        completedAt
        unit {
          id
          positionStatus
          dispatchStatus
        }
      }
    }
  }
`

export const COMPLETE_DISPATCH = gql`
  mutation CompleteDispatch($id: ID, $time: DateTime) {
    completeDispatch(id: $id, time: $time) {
      id
      completedAt
      unit {
        id
        positionStatus
        dispatchStatus
      }
      submissions {
        id
      }
    }
  }
`

const MARK_FILE_READ = gql`
  mutation MarkFileRead($where: FileWhereUniqueInput!) {
    markFileRead(where: $where) {
      id
      hasRead
    }
  }
`

export const useMarkFileRead = options => {
  const defaultOptions = {
    refetchQueries: ['GetFiles'],
    ...options
  }
  const [markFileRead, { data, error, loading }] = useMutation(
    MARK_FILE_READ,
    defaultOptions
  )
  const file = optionalChaining(() => data.markFileRead)

  return {
    file,
    error,
    loading,
    markFileRead
  }
}

const UPDATE_WORKFLOW = gql`
  mutation UpdateWorkflow(
    $where: WorkflowWhereUniqueInput!
    $data: WorkflowUpdateInput!
  ) {
    updateWorkflow(data: $data, where: $where) {
      id
      name
      displayTags {
        id
        name
        color
      }
      steps {
        id
        type
        forms {
          id
          name
        }
        bulkSubmit
      }
    }
  }
`

const CREATE_WORKFLOW = gql`
  mutation CreateWorkflow($data: WorkflowCreateInput!) {
    createWorkflow(data: $data) {
      id
      name
      displayTags {
        id
        name
        color
      }
      steps {
        id
        type
        forms {
          id
          name
        }
        bulkSubmit
      }
    }
  }
`

export const useUpdateWorkflow = options => {
  const defaultOptions = {
    refetchQueries: ['GetWorkflows']
  }
  const [updateWorkflow, { data, error, loading }] = useMutation(
    UPDATE_WORKFLOW,
    defaultOptions
  )
  const workflow = optionalChaining(() => data.updateOneWorkflow)

  return {
    workflow,
    error,
    loading,
    updateWorkflow
  }
}

export const useCreateWorkflow = options => {
  const defaultOptions = {
    refetchQueries: ['GetWorkflows']
  }
  const [createWorkflow, { data, error, loading }] = useMutation(
    CREATE_WORKFLOW,
    defaultOptions
  )
  const workflow = optionalChaining(() => data.createOneWorkflow)

  return {
    workflow,
    error,
    loading,
    createWorkflow
  }
}

export const LINK_RESPONSE_TO_STEP = gql`
  mutation LinkResponseToStep($stepId: ID!, $responseId: ID!) {
    linkResponseToStep(stepId: $stepId, responseId: $responseId) {
      ... on FormResponse {
        id
      }
      ... on BulkSubmission {
        id
      }
    }
  }
`
