/** @jsxImportSource theme-ui */

import { Fragment, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { NetworkStatus, useQuery } from '@apollo/client'
import { cleanGraphQLError } from '../components/Forms/FormError'
import { Box, Flex } from 'theme-ui'
import {
  GET_HOMEBASE_INFO,
  GET_HOMEBASE_TASKS,
  useGetCurrentAgencyContext,
  useGetTodaysAssignment
} from '../hooks/queries'
import get from 'lodash/get'
import { CenteredContent } from '../components'
import DispatchStatusColor, {
  DispatchColorKey
} from '../components/Dispatch/DispatchStatusColor'
import { prettyDate } from 'src/utils'
import { RolesOnly } from '../services/auth'
import { CreateBulletinModalButton } from '../components/Forms/CreateBulletin'

import WeatherWidget from '../components/HomeBase/WeatherWidget'
import PayWallScreen, {
  getTruthyPermissionsFromSettings
} from '../components/PayWalls/PayWallScreen'

import ImportantSubmissionList from '../components/Reporting/ImportantSubmissionList'
import moment from 'moment'
import DocumentList from '../components/Operations/Documents/DocumentList'
import FormList from '../components/Operations/Forms/FormList'
import { useNetworkStatus } from '../hooks/useNetworkStatus'
import Card from '../components/Shared/Elements/Card'
import ListGroup from '../components/Shared/Elements/ListGroup'
import ListGroupItem from '../components/Shared/Elements/ListGroupItem'
import ListGroupItemHeading from '../components/Shared/Elements/ListGroupItemHeading'

import { DISPATCH_SUB } from '../hooks/subscriptions'

import NWSAlets from '../components/HomeBase/NwsAlerts'
import Spinner from 'src/images/icons/Spinner'
import OfflineDisabledInlineTip from '../components/Shared/OfflineDisabledInlineTip'
import { useNavigate } from 'react-router'
import SubscriptionButton from '../components/Shared/SubscriptionButton'
import CustomTabs from '../components/Shared/CustomTabs'

import { Overline } from '../components/Shared'

import ModalButton from '../components/Shared/ModalButton'
import DailyAssignmentLabel from '../components/DailyAssignmentLabel'
import DailyAssignmentForm from '../components/Forms/DailyAssignmentForm'
import BulletinList from '../components/HomeBase/Bulletin'
import Icon from '../components/Shared/Icon'
import { UrgencyIcon } from '../components/Bulletins/BulletinIcons'
import ViewFileModal from '../components/Operations/Documents/ViewFileModal'
import { BulletinModalContentContainer } from '../components/Bulletins/BulletinRichTextModal'
import { FormSubmitRendererModalButton } from '../components/Operations/Forms/formSubmitRenderer/FormSubmitRendererModalButton'
import CollapsablePanel from '../components/Shared/CollapsablePanel'
import BulkIncident from '../components/QuickSurvey/BulkIncident'
import Badge from '../components/Shared/Elements/Badge'

import BulkSubmissionEditContent from '../components/Stats/BulkSubmissionEditContent'
import Button from '../components/Shared/Elements/Button'

import CustomSelectInput from '../components/Forms/CustomSelectInput'
import QuickSurvey from './QuickSurveyBranch'
import ReactConfetti from 'react-confetti'
import { presetColors } from '../components/Shared/HexColorPicker'
import EditSubmissionsList from '../components/QuickSurvey/EditSubmissionsList'

import LinkResponseToStepModalButton from '../components/HomeBase/Workflows/LinkResponseToStepModalButton'
import { StopPropogation } from '../components/Shared/StopPropogation'
import QuestionTooltip from '../components/Shared/QuestionTooltip'

const StyledListGroupItem = styled(ListGroupItem)`
  ${p => `border-left: 3px solid ${p.theme.colors[p.accent]};`},
`

const HomeBase = () => {
  const isOnline = useNetworkStatus()

  const {
    error: agencyError,
    currentAgency,
    user,
    loading
  } = useGetCurrentAgencyContext()

  const e = agencyError && cleanGraphQLError(agencyError.message)

  if (e && isOnline)
    return (
      <CenteredContent>
        Oops, there was an error. Try reloading your browser.
      </CenteredContent>
    )

  if (!currentAgency) return <Spinner centered />

  const hasWorkflows = getTruthyPermissionsFromSettings(
    currentAgency?.settings
  )?.includes('workflows')

  return (
    <Box>
      <OfflineDisabledInlineTip />
      {!loading && <NWSAlets />}

      {hasWorkflows ? (
        <CustomTabs
          navItemComponent={() => (
            <Flex sx={{ alignItems: 'center', gap: '5px' }}>
              <ModalButton
                modalHeader={'Submit a Form'}
                buttonLabel={'Other Forms'}
                buttonProps={{
                  variant: 'secondary'
                }}
              >
                {({ toggle }) => (
                  <FormList
                    hideFilters={['showSupReview', 'showArchived']}
                    submitToastText="Form Submitted"
                    hideCreateButton
                    hideEdit
                    hideResponseModal
                    onFormSubmitFinish={toggle}
                  />
                )}
              </ModalButton>
              <ModalButton
                buttonLabel={'Submit Incident'}
                contentSize="lg"
                contentHeight={'90vh'}
                bodyStyles={{
                  overflow: 'auto'
                }}
                modalProps={{
                  overflow: 'auto',
                  shouldCloseOnEsc: false,
                  checkOnClose: true,
                  stickySubmit: true
                }}
                modalHeader={'Submit incident'}
                buttonProps={{
                  variant: 'primary'
                }}
              >
                {({ toggle }) => {
                  return (
                    <QuickSurvey
                      onFinish={toggle}
                      useTodaysPosition={true}
                      typeOnly={false}
                      stickySubmit={true}
                      extendedQuickSurvey={true}
                    />
                  )
                }}
              </ModalButton>
            </Flex>
          )}
          tabs={[
            {
              linkTitle: "Today's Work",
              component: (
                <Workflow currentAgency={currentAgency} user={user} />
              )
            },
            {
              linkTitle: "What's Happening",
              component: (
                <WhatsHappening currentAgency={currentAgency} />
              )
            },
            {
              linkTitle: 'My Past Work',
              component: <MyActivityLog />
            }
          ]}
        />
      ) : (
        <OriginalView currentAgency={currentAgency} />
      )}
    </Box>
  )
}

const MyActivityLog = () => {
  return (
    <Box
      sx={{
        py: 40
      }}
    >
      <EditSubmissionsList />
    </Box>
  )
}

const OriginalView = ({ currentAgency }) => {
  return (
    <Flex
      sx={{
        flexDirection: 'row',
        justifyContent: 'stretch',
        gap: '20px',
        flexWrap: 'wrap'
      }}
    >
      <PayWallScreen featureLabel="comms" hideContent>
        <HomeBaseLeftPanel />
      </PayWallScreen>
      <HomeBaseRightPanel currentAgency={currentAgency} />
    </Flex>
  )
}

const HomeBaseLeftPanel = () => {
  return (
    <Box
      sx={{
        margin: '0 auto 0 0',
        mb: ['50px', null, null, null, null, '0px'],
        pb: 20,
        flex: 1,
        minWidth: '300px'
      }}
    >
      <Flex
        sx={{
          width: '100%',
          py: '10px',
          height: '50px',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <h5>Bulletins</h5>
        <RolesOnly roles={['ADMIN', 'SUPERVISOR']}>
          <CreateBulletinModalButton />
        </RolesOnly>
      </Flex>

      <BulletinList />
    </Box>
  )
}

const HomeBaseRightPanel = ({ currentAgency }) => {
  const navigate = useNavigate()
  const startDate = moment(new Date())
    .startOf('D')
    .toISOString()
  const endDate = moment(new Date())
    .endOf('D')
    .toISOString()

  const { data, loading, refetch } = useQuery(GET_HOMEBASE_INFO, {
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        incidentTime: {
          gt: startDate,
          lt: endDate
        },
        important: {
          equals: true
        }
      }
    }
  })

  const importantIncidents = get(data, 'myAgenciesSubmissions', [])

  const activeDispatches = get(
    data || [],
    'myAgenciesSubmissionsWithActiveDispatches',
    []
  )

  const docList = useMemo(
    () => (
      <DocumentList
        pinnedOnly
        hideSearch
        hideLoadMore
        hideCreateButton
        hidePin
        hideEdit
      />
    ),
    []
  )

  const formList = useMemo(
    () => (
      <FormList
        pinnedOnly
        hideSearch
        hideLoadMore
        hideEdit
        hidePin
        hid
        hideCreateButton
      />
    ),
    []
  )

  return (
    <Box
      sx={{
        alignItems: 'stretch',
        flexDirection: 'column',
        margin: '0 0 0 auto',
        flex: 1,
        minWidth: '300px',
        mb: ['50px', null, null, null, null, '0px']
      }}
    >
      <Box sx={{ mb: 40, width: '100%' }}>
        <PayWallScreen featureLabel="forms" hideContent>
          <Box
            sx={{ py: '10px', height: '50px', alignItems: 'center' }}
          >
            <h5>Documents</h5>
          </Box>
          {docList}
        </PayWallScreen>
      </Box>
      <Box sx={{ mb: 40, width: '100%' }}>
        <PayWallScreen featureLabel="forms" hideContent>
          <Box
            sx={{ py: '10px', height: '50px', alignItems: 'center' }}
          >
            <h5 sx={{ pb: 10 }}>Forms</h5>
          </Box>
          {formList}
        </PayWallScreen>
      </Box>
      <PayWallScreen hideContent featureLabel="dispatching">
        <Box sx={{ mb: 40, width: '100%' }}>
          <Flex
            sx={{
              justifyContent: 'space-between',
              py: '10px',
              height: '50px',
              alignItems: 'center'
            }}
          >
            <h5
              sx={{
                display: 'flex',
                gap: '4px',
                alignItems: 'center'
              }}
            >
              Active Incidents{' '}
              <SubscriptionButton
                startOn
                minimal
                variables={{
                  agencyId: currentAgency.id
                }}
                query={DISPATCH_SUB}
                onSubscriptionData={() => {
                  // allow for cache to update...
                  setTimeout(() => refetch(), 300)
                }}
                externalLoading={loading}
              />
            </h5>
            <DispatchColorKey />
          </Flex>

          <Card>
            <ListGroup sx={{ padding: '1rem' }}>
              {activeDispatches && activeDispatches.length > 0 ? (
                activeDispatches.map(p => {
                  return (
                    <StyledListGroupItem
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigate('/agency/dispatches')}
                      key={p.id}
                    >
                      <Flex
                        sx={{
                          justifyContent: 'space-between'
                        }}
                      >
                        <ListGroupItemHeading>
                          {prettyDate(p.incidentTime, 'HH:mm')} -{' '}
                          {p.type && p.type.label}{' '}
                        </ListGroupItemHeading>

                        <ListGroupItemHeading>
                          {p.location &&
                            p.location.position &&
                            `${p.location.position.name}`}
                        </ListGroupItemHeading>
                      </Flex>
                      <Flex>
                        {p.dispatches.map(d => (
                          <DispatchStatusColor
                            style={{ marginRight: '3px' }}
                            oScene={d.onScene}
                            completedAt={d.completedAt}
                            enRoute={d.enRoute}
                          >
                            {d.unit ? d.unit.name : 'Unassigned'}
                          </DispatchStatusColor>
                        ))}
                      </Flex>
                    </StyledListGroupItem>
                  )
                })
              ) : (
                <Box>
                  <CenteredContent height={'200px'}>
                    {loading && <Spinner />}
                    No active calls{' '}
                  </CenteredContent>
                </Box>
              )}
            </ListGroup>
          </Card>
        </Box>
      </PayWallScreen>
      <Box sx={{ mb: 40, width: '100%' }}>
        <h5>Today's Notable Incidents</h5>
        <Box variant="card">
          <ImportantSubmissionList
            data={importantIncidents}
            loading={loading}
          />
        </Box>
      </Box>
      <Box sx={{ mb: 40, width: '100%' }}>
        <h5>Weather</h5>
        <Box variant="card">
          <WeatherWidget
            {...currentAgency.settings}
            timezone={currentAgency.timezone}
          />
        </Box>
      </Box>
    </Box>
  )
}

const Workflow = ({ user }) => {
  const [showedWorkflow, setShowedWorkflow] = useState(0)
  const ts = useGetTodaysAssignment()

  const todaysResponders = ts.data?.todaysResponders
  const variables = {
    assignments: todaysResponders?.map(r => ({
      responderId: r.responderId,
      positionId: r.positionId
    }))
  }
  const {
    data,
    refetch,
    loading: loadingTasks,
    networkStatus,
    error
  } = useQuery(GET_HOMEBASE_TASKS, {
    skip: !todaysResponders,
    notifyOnNetworkStatusChange: true,
    variables
  })

  if (error && !data) {
    return (
      <CenteredContent>
        Oops, there was an error. Try reloading your browser.
      </CenteredContent>
    )
  }

  const initialLoading =
    networkStatus !== NetworkStatus.refetch && loadingTasks

  const workflowData = data?.myWorkflow?.workflows || {}

  const files = data?.myWorkflow?.files || []
  const bulletins = data?.myWorkflow?.bulletins || []

  const filesToReview = files?.filter(f => !f.hasRead)
  const bulletinsToReview = bulletins?.filter(b => !b.hasRead)
  const reviewedFiles = files?.filter(f => f.hasRead)
  const reviewedBulletins = bulletins?.filter(b => b.hasRead)

  const steps = workflowData?.[showedWorkflow]?.steps || []

  return (
    <Box
      sx={{
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        pb: 20,
        position: 'relative'
      }}
    >
      <h5
        sx={{
          my: '40px',
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: '5px'
        }}
      >
        Hello{' '}
        <ModalButton
          buttonProps={{
            variant: 'link',
            sx: {
              p: '0px',
              fontSize: 3,
              textTransform: 'none',
              ml: '5px'
            }
          }}
          modalHeader={'Set Assignment'}
          buttonLabel={
            todaysResponders?.length < 1 ? (
              `${user.name} (Click to set assignment)`
            ) : (
              <>
                {' '}
                <DailyAssignmentLabel emptyText="Click to set assignment" />
                ,{' '}
              </>
            )
          }
        >
          {({ toggle }) => <DailyAssignmentForm onFinish={toggle} />}
        </ModalButton>{' '}
        today is {moment().format('dddd, MMMM Do YYYY')}.
      </h5>
      {initialLoading ? (
        <Spinner centered />
      ) : !todaysResponders ? (
        <CenteredContent height={'300px'}>
          Please set your assignments to continue.
        </CenteredContent>
      ) : (
        <Flex
          sx={{
            flexDirection: 'column',
            flexWrap: 'wrap',
            justifyContent: 'space-between',

            position: 'relative',
            // ml: '30px',
            maxWidth: '800px'
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '0px',
              left: '35px',
              bottom: '0px',
              bg: 'gray300',
              width: '1px',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1
            }}
          />

          {(bulletins?.length > 0 || files?.length > 0) && (
            <Card
              sx={{
                gap: '15px',
                flexDirection: 'column',
                py: '20px',
                bg: 'background',
                p: 20,
                zIndex: 2
              }}
            >
              {bulletins?.length > 0 && (
                <Flex
                  sx={{
                    gap: '10px',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    borderBottom: '1px solid',
                    borderColor: 'gray200'
                  }}
                >
                  <strong>Bulletins</strong>
                  {bulletinsToReview?.map((bulletin, k) => {
                    return (
                      <BulletinWorkflowItem
                        key={k}
                        bulletin={bulletin}
                      />
                    )
                  })}

                  {reviewedBulletins?.length > 0 && (
                    <CollapsablePanel
                      buttonOptions={{
                        text:
                          'Show Reviewed Bulletins' +
                          ` (${reviewedBulletins?.length})`,
                        openIcon: 'down arrow',
                        closeIcon: 'up arrow',
                        style: {
                          borderBottom: 'none'
                        }
                      }}
                    >
                      {() => (
                        <Flex
                          sx={{
                            mt: '20px',
                            gap: '10px',
                            flexDirection: 'column',
                            flexWrap: 'wrap'
                          }}
                        >
                          {reviewedBulletins?.map((bulletin, k) => {
                            return (
                              <BulletinWorkflowItem
                                key={k}
                                bulletin={bulletin}
                              />
                            )
                          })}
                        </Flex>
                      )}
                    </CollapsablePanel>
                  )}
                </Flex>
              )}
              {files?.length > 0 && (
                <Flex
                  sx={{
                    my: '20px',
                    gap: '10px',
                    flexDirection: 'column',
                    flexWrap: 'wrap',
                    borderBottom: '1px solid',
                    borderColor: 'gray200'
                  }}
                >
                  <strong>Documents</strong>

                  {filesToReview?.map((file, k) => {
                    return <FileWorkflowItem key={k} file={file} />
                  })}

                  {reviewedFiles?.length > 0 && (
                    <CollapsablePanel
                      buttonOptions={{
                        text:
                          'Show Reviewed Documents' +
                          ` (${reviewedFiles?.length})`,
                        openIcon: 'down arrow',
                        closeIcon: 'up arrow',
                        style: {
                          borderBottom: 'none'
                        }
                      }}
                    >
                      {() => (
                        <Flex
                          sx={{
                            mt: '20px',
                            gap: '10px',
                            flexDirection: 'column',
                            flexWrap: 'wrap'
                          }}
                        >
                          {reviewedFiles?.map((file, k) => {
                            return (
                              <FileWorkflowItem key={k} file={file} />
                            )
                          })}
                        </Flex>
                      )}
                    </CollapsablePanel>
                  )}
                </Flex>
              )}
            </Card>
          )}

          {todaysResponders?.length > 1 ? (
            <Flex
              sx={{
                width: '100%',
                justifyContent: 'flex-end',
                py: 20
              }}
            >
              <CustomSelectInput
                sx={{
                  minWidth: '200px',
                  zIndex: 10
                }}
                id="workflow"
                name="workflow"
                value={
                  todaysResponders[showedWorkflow] && {
                    value: showedWorkflow,
                    label: `${todaysResponders[showedWorkflow]
                      .responderName || ''} - ${todaysResponders[
                      showedWorkflow
                    ].positionName || ''}`
                  }
                }
                onChange={e => {
                  setShowedWorkflow(e.value)
                }}
                options={todaysResponders?.map((r, k) => ({
                  label: `${r.responderName} - ${r.positionName}`,
                  value: k
                }))}
              />
            </Flex>
          ) : (
            <Fragment />
          )}

          {!steps?.length ? (
            <Flex
              sx={{
                bg: 'background',
                mt: '30px',
                p: '20px',
                flexDirection: 'column',
                alignItems: 'space-between',
                zIndex: 2
              }}
            >
              <Flex
                sx={{
                  mb: '10px',
                  gap: '5px',
                  alignItems: 'center'
                }}
              >
                No tasks found
              </Flex>
            </Flex>
          ) : (
            steps.map((task, k) => {
              const bulkSubmissions = task.responses?.filter(
                r => r?.__typename === 'BulkSubmission'
              )
              const notDraftBulkOrUnlinkedToStep = bulkSubmissions?.filter(
                fr => !fr.draft && fr.stepId
              )
              return (
                <Box
                  sx={{
                    mt: '30px'
                  }}
                >
                  <Flex
                    sx={{
                      width: '100%',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Overline> {task.workflowName}</Overline>
                  </Flex>
                  <Card
                    key={k}
                    sx={{
                      position: 'relative',
                      bg: 'background',

                      p: '20px',
                      flexDirection: 'column',
                      alignItems: 'space-between',
                      zIndex: 500
                    }}
                  >
                    <Flex
                      sx={{
                        mb: '30px',
                        gap: '5px',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <Box>
                        <strong>{task.timestamp}</strong> -{' '}
                        {task.title}{' '}
                      </Box>

                      <Flex
                        sx={{
                          alignItems: 'center'
                        }}
                      >
                        <Overline
                          sx={{
                            fontSize: '10px',
                            mb: '0px'
                          }}
                        >
                          {task.type} in:
                        </Overline>
                        <Flex
                          sx={{
                            gap: '5px',
                            ml: '10px',
                            alignItems: 'center'
                          }}
                        >
                          {task.displayTags?.map(t => (
                            <Badge
                              bg={t.color}
                              size="sm"
                              outline
                              key={t.id}
                            >
                              {t.name}
                            </Badge>
                          ))}
                        </Flex>
                      </Flex>
                    </Flex>
                    <Box
                      sx={{
                        px: 20
                      }}
                    >
                      {task.forms?.map((form, k) => {
                        const formResponses = task.responses?.filter(
                          r =>
                            r?.__typename === 'FormResponse' &&
                            r.form.id === form.id
                        )

                        const notDraftResponsesOrUnlinkedToStep = formResponses?.filter(
                          fr => !fr.draft && fr.stepId
                        )

                        return (
                          <Box key={k} sx={{ mb: '20px' }}>
                            <Flex
                              sx={{
                                flexDirection: 'row',
                                width: '100%',
                                justifyContent: 'space-between',
                                gap: '10px',
                                alignItems: 'center'
                              }}
                            >
                              <Flex
                                sx={{
                                  fontWeight: 'bold',
                                  alignItems: 'center',
                                  gap: '10px',
                                  color: notDraftResponsesOrUnlinkedToStep?.length
                                    ? 'success'
                                    : ''
                                }}
                              >
                                <TaskBullet
                                  complete={
                                    notDraftResponsesOrUnlinkedToStep?.length
                                  }
                                />
                                {form?.name}
                              </Flex>

                              {formResponses?.length < 1 && (
                                <FormSubmitRendererModalButton
                                  modalButtonProps={{
                                    buttonLabel: 'Start',
                                    buttonProps: {
                                      variant: 'primary',
                                      icon: 'right arrow',
                                      iconPosition: 'right',
                                      size: 'sm'
                                    }
                                  }}
                                  onFinish={refetch}
                                  onDraftFinish={refetch}
                                  formId={form?.id}
                                  stepId={task?.id}
                                />
                              )}
                            </Flex>
                            <Box
                              sx={{
                                my: '8px'
                              }}
                            >
                              {formResponses?.map((fr, k) => {
                                const isUnlinkedToStep = !fr?.stepId
                                return (
                                  <>
                                    <FormSubmitRendererModalButton
                                      key={k}
                                      modalButtonProps={{
                                        CustomButtonComponent: p => {
                                          return (
                                            <Flex
                                              {...p}
                                              sx={{
                                                gap: '10px',
                                                bg: 'gray200',
                                                px: '10px',
                                                py: '5px',
                                                my: '5px',
                                                justifyContent:
                                                  'space-between',
                                                alignItems: 'center',
                                                width: '100%',
                                                flexWrap: 'wrap'
                                              }}
                                            >
                                              <Flex
                                                sx={{
                                                  gap: '5px'
                                                }}
                                              >
                                                {fr.draft && (
                                                  <Badge
                                                    size="sm"
                                                    variant="warning"
                                                  >
                                                    Draft
                                                  </Badge>
                                                )}
                                                <Overline
                                                  sx={{
                                                    mb: '0px',
                                                    display: 'flex',
                                                    alignItems:
                                                      'center',
                                                    gap: '5px'
                                                  }}
                                                >
                                                  {isUnlinkedToStep && (
                                                    <QuestionTooltip
                                                      iconHeight={13}
                                                      tip={
                                                        'This response is not linked to a workflow but looks like it may be a match for this task.  This means you may have submitted this outside the workflow interface.  Click the "Link to Step" to connect it to this unfinished task.'
                                                      }
                                                    />
                                                  )}
                                                  {
                                                    fr.submittedBy
                                                      .name
                                                  }{' '}
                                                  <Box
                                                    sx={{
                                                      fontSize: '10px'
                                                    }}
                                                  >
                                                    {prettyDate(
                                                      fr.createdAt
                                                    )}
                                                  </Box>
                                                </Overline>
                                                {fr.summary?.join(
                                                  ', '
                                                )}
                                              </Flex>
                                              <Flex
                                                sx={{
                                                  gap: '5px'
                                                }}
                                              >
                                                {isUnlinkedToStep && (
                                                  <StopPropogation>
                                                    <LinkResponseToStepModalButton
                                                      responseId={
                                                        fr.id
                                                      }
                                                      stepId={
                                                        task?.id
                                                      }
                                                      stepTimestamp={
                                                        task.timestamp
                                                      }
                                                      stepTitle={
                                                        task.title
                                                      }
                                                      variables={
                                                        variables
                                                      }
                                                    />
                                                  </StopPropogation>
                                                )}
                                                <Button
                                                  variant="secondary"
                                                  icon="pencil"
                                                  size="sm"
                                                />
                                              </Flex>
                                            </Flex>
                                          )
                                        }
                                      }}
                                      fetchFormResponse
                                      formId={form?.id}
                                      formResponseId={fr.id}
                                    />
                                  </>
                                )
                              })}
                            </Box>
                          </Box>
                        )
                      })}
                      {task.bulkSubmit && (
                        <Box sx={{ mb: '20px' }}>
                          <Flex
                            sx={{
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              gap: '10px',
                              alignItems: 'center'
                            }}
                          >
                            <Flex
                              sx={{
                                fontWeight: 'bold',
                                alignItems: 'center',
                                gap: '10px',
                                color: notDraftBulkOrUnlinkedToStep?.length
                                  ? 'success'
                                  : ''
                              }}
                            >
                              <TaskBullet
                                complete={
                                  notDraftBulkOrUnlinkedToStep?.length
                                }
                              />
                              Bulk Submission
                            </Flex>

                            {notDraftBulkOrUnlinkedToStep?.length <
                              1 && (
                              <ModalButton
                                modalHeader={`Bulk Submission`}
                                buttonProps={{
                                  iconPosition: 'right',
                                  icon: 'right arrow',

                                  size: 'sm',
                                  variant: 'primary'
                                }}
                                workflowStepId={task?.id}
                                buttonLabel={'Start'}
                                modalProps={{
                                  stickySubmit: true,
                                  shouldCloseOnEsc: false
                                }}
                                contentHeight={'90vh'}
                                bodyStyles={{
                                  overflow: 'auto'
                                }}
                              >
                                {({ toggle }) => {
                                  return (
                                    <BulkIncident
                                      hideDateInput
                                      hideResponderInputs
                                      stepId={task?.id}
                                      onFinish={() => {
                                        toggle()
                                        refetch()
                                      }}
                                    />
                                  )
                                }}
                              </ModalButton>
                            )}
                          </Flex>
                          <Box
                            sx={{
                              my: '8px'
                            }}
                          >
                            {bulkSubmissions?.map(bs => {
                              const isUnlinkedToStep = !bs?.stepId
                              return (
                                <ModalButton
                                  CustomButtonComponent={p => {
                                    return (
                                      <Flex
                                        {...p}
                                        sx={{
                                          gap: '10px',
                                          bg: 'gray200',
                                          px: '10px',
                                          py: '5px',
                                          my: '5px',
                                          justifyContent:
                                            'space-between',
                                          alignItems: 'center',
                                          width: '100%'
                                        }}
                                      >
                                        <Overline
                                          sx={{
                                            mb: '0px'
                                          }}
                                        >
                                          {
                                            bs.breakdown[0]?.reporter
                                              ?.name
                                          }{' '}
                                          at{' '}
                                          {prettyDate(bs.createdAt)}
                                        </Overline>
                                        <Flex>
                                          {isUnlinkedToStep && (
                                            <StopPropogation>
                                              <LinkResponseToStepModalButton
                                                responseId={
                                                  bs.submissionIds[0]
                                                }
                                                stepId={task?.id}
                                                stepTimestamp={
                                                  task.timestamp
                                                }
                                                stepTitle={task.title}
                                                variables={variables}
                                              />
                                            </StopPropogation>
                                          )}
                                          <Button
                                            variant="link"
                                            icon="pencil"
                                            size="sm"
                                          />
                                        </Flex>
                                      </Flex>
                                    )
                                  }}
                                >
                                  {() => (
                                    <BulkSubmissionEditContent
                                      {...bs}
                                      dispatches={
                                        bs.breakdown[0].resources ||
                                        []
                                      }
                                      note={
                                        bs?.bulkNotes?.[1]?.text ||
                                        bs?.bulkNotes?.[0]?.text
                                      }
                                      submissionIds={bs.submissionIds}
                                      breakdown={bs.breakdown}
                                      createdAt={bs.createdAt}
                                      incidentTime={bs.incidentTime}
                                      cacheKey={'myWorkflow'}
                                      cacheQuery={GET_HOMEBASE_TASKS}
                                      variables={variables}
                                      notes={bs.bulkNotes}
                                      id={bs.id}
                                    />
                                  )}
                                </ModalButton>
                              )
                            })}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Card>
                </Box>
              )
            })
          )}
        </Flex>
      )}
    </Box>
  )
}

const TaskBullet = ({ complete }) => {
  return (
    <Flex
      sx={{
        border: '1px solid',
        borderColor: 'gray200',
        width: '25px',
        height: '25px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        bg: complete && 'success'
      }}
    >
      {complete ? (
        <>
          <ReactConfetti
            width={500}
            height={500}
            numberOfPieces={30}
            recycle={false}
            colors={presetColors}
          />

          <Icon
            color={'white'}
            icon="checkmark"
            width={12}
            height={12}
          />
        </>
      ) : (
        ''
      )}
    </Flex>
  )
}

const BulletinWorkflowItem = ({ bulletin, ...rest }) => {
  return (
    <Flex
      sx={{
        width: '100%',
        gap: 10,
        alignItems: 'flex-start',
        justifyContent: 'space-between'
      }}
      {...rest}
    >
      <Flex
        sx={{
          alignItems: 'flex-start',
          gap: '10px'
        }}
      >
        <UrgencyIcon urgency={bulletin.urgency} />{' '}
        <Box sx={{ fontWeight: 'bold', mb: '5px' }}>
          <Box>
            {bulletin.title}
            <Overline>
              {bulletin?.author?.name} at {bulletin.timestamp}
            </Overline>
          </Box>
        </Box>
      </Flex>
      <ModalButton
        buttonLabel={bulletin.hasRead ? 'Complete' : 'View'}
        buttonProps={{
          variant: bulletin.hasRead ? 'success' : 'primary',
          icon: bulletin.hasRead ? 'checkmark' : 'right arrow',
          iconPosition: 'right',
          size: 'sm'
        }}
      >
        {({ toggle }) => {
          return (
            <BulletinModalContentContainer
              id={bulletin.id}
              onClose={toggle}
            />
          )
        }}
      </ModalButton>
    </Flex>
  )
}

const FileWorkflowItem = ({ file, ...rest }) => {
  return (
    <Flex
      sx={{
        width: '100%',
        gap: 10,
        alignItems: 'flex-start',
        justifyContent: 'space-between'
      }}
      {...rest}
    >
      <Flex
        sx={{
          alignItems: 'flex-start',
          gap: '10px'
        }}
      >
        <Icon icon="pin" width={15} height={15} />{' '}
        <Box sx={{ fontWeight: 'bold', mb: '5px' }}>
          {file.name}
          <Overline>{file?.createdBy?.name}</Overline>
        </Box>
      </Flex>
      <ModalButton
        buttonLabel={file.hasRead ? 'Complete' : 'View'}
        buttonProps={{
          variant: file.hasRead ? 'success' : 'primary',
          icon: file.hasRead ? 'checkmark' : 'right arrow',
          iconPosition: 'right',
          size: 'sm'
        }}
      >
        {({ toggle }) => {
          return <ViewFileModal closeModal={toggle} f={file} />
        }}
      </ModalButton>
    </Flex>
  )
}

const WhatsHappening = ({ currentAgency }) => {
  const navigate = useNavigate()
  const startDate = moment(new Date())
    .startOf('D')
    .toISOString()
  const endDate = moment(new Date())
    .endOf('D')
    .toISOString()

  const { data, loading, refetch } = useQuery(GET_HOMEBASE_INFO, {
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        incidentTime: {
          gt: startDate,
          lt: endDate
        },
        important: {
          equals: true
        }
      }
    }
  })

  const importantIncidents = get(data, 'myAgenciesSubmissions', [])

  const activeDispatches = get(
    data || [],
    'myAgenciesSubmissionsWithActiveDispatches',
    []
  )

  return (
    <Box
      sx={{
        alignItems: 'stretch',
        flexDirection: 'column',
        margin: '0 0 0 auto',
        flex: 1,
        minWidth: '300px',
        p: 20,
        mb: ['50px', null, null, null, null, '0px']
      }}
    >
      <NWSAlets />
      <PayWallScreen hideContent featureLabel="dispatching">
        <Box sx={{ mb: 40, width: '100%' }}>
          <Flex
            sx={{
              justifyContent: 'space-between',
              py: '10px',
              height: '50px',
              alignItems: 'center'
            }}
          >
            <h5
              sx={{
                display: 'flex',
                gap: '4px',
                alignItems: 'center'
              }}
            >
              Active Incidents{' '}
              <SubscriptionButton
                startOn
                minimal
                variables={{
                  agencyId: currentAgency.id
                }}
                query={DISPATCH_SUB}
                onSubscriptionData={() => {
                  // allow for cache to update...
                  setTimeout(() => refetch(), 300)
                }}
                externalLoading={loading}
              />
            </h5>
            <DispatchColorKey />
          </Flex>

          <Card>
            <ListGroup sx={{ padding: '1rem' }}>
              {activeDispatches && activeDispatches.length > 0 ? (
                activeDispatches.map(p => {
                  return (
                    <StyledListGroupItem
                      style={{ cursor: 'pointer' }}
                      onClick={() => navigate('/agency/dispatches')}
                      key={p.id}
                    >
                      <Flex
                        sx={{
                          justifyContent: 'space-between'
                        }}
                      >
                        <ListGroupItemHeading>
                          {prettyDate(p.incidentTime, 'HH:mm')} -{' '}
                          {p.type && p.type.label}{' '}
                        </ListGroupItemHeading>

                        <ListGroupItemHeading>
                          {p.location &&
                            p.location.position &&
                            `${p.location.position.name}`}
                        </ListGroupItemHeading>
                      </Flex>
                      <Flex>
                        {p.dispatches.map(d => (
                          <DispatchStatusColor
                            style={{ marginRight: '3px' }}
                            oScene={d.onScene}
                            completedAt={d.completedAt}
                            enRoute={d.enRoute}
                          >
                            {d.unit ? d.unit.name : 'Unassigned'}
                          </DispatchStatusColor>
                        ))}
                      </Flex>
                    </StyledListGroupItem>
                  )
                })
              ) : (
                <Box>
                  <CenteredContent height={'200px'}>
                    {loading && <Spinner />}
                    No active calls{' '}
                  </CenteredContent>
                </Box>
              )}
            </ListGroup>
          </Card>
        </Box>
      </PayWallScreen>
      <Box sx={{ mb: 40, width: '100%' }}>
        <h5>Today's Notable Incidents</h5>
        <Box variant="card">
          <ImportantSubmissionList
            data={importantIncidents}
            loading={loading}
          />
        </Box>
      </Box>
      <Box sx={{ mb: 40, width: '100%' }}>
        <h5>Weather</h5>
        <Box variant="card">
          <WeatherWidget
            {...currentAgency.settings}
            timezone={currentAgency.timezone}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default HomeBase
