import { useGetTodaysAssignment } from '../hooks/queries'
import { optionalChaining } from 'src/utils'

const DailyAssignmentLabel = ({ emptyText = 'Not Set' }) => {
  const { data } = useGetTodaysAssignment()
  const positionNames = optionalChaining(() =>
    data?.todaysResponders
      .map(i =>
        i.responderName
          ? `${i.responderName} (${i.positionName || emptyText})`
          : i.positionName
      )
      .join(', ')
  )

  if (!positionNames) return emptyText
  return `${positionNames}`
}

export default DailyAssignmentLabel
